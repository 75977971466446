import addressesAparmentsImage from "./images/addresses_apartments.webp";
import addressesAparmentsIcon from "./images/addresses_apartments_icon.webp";
import addressesOperationsImage from "./images/addresses_operations.webp";
import addressesOperationsIcon from "./images/addresses_operations_icon.webp";
import aparmentsImage from "./images/apartments.webp";
import aparmentsIcon from "./images/apartments_icon.webp";
import boqImage from "./images/boq.webp";
import boqIcon from "./images/boq_icon.webp";
import bundleUpdateImage from "./images/bundle_update.webp";
import bundleUpdateIcon from "./images/bundle_update_icon.webp";
import circuitCreationImage from "./images/circuit_creation.webp";
import circuitCreationIcon from "./images/circuit_creation_icon.webp";
import civilWorksImage from "./images/civil_works.webp";
import civilWorksIcon from "./images/civil_works_icon.webp";
import deviceCreationImage from "./images/device_creation_main.webp";
import deviceCreationIcon from "./images/device_creation_icon.webp";
import extravaluesImage from "./images/extravalues.webp";
import extravaluesIcon from "./images/extravalues_icon.webp";
import identifierUpdateImage from "./images/identifier_update_main.webp";
import identifierUpdateIcon from "./images/identifier_update_icon.webp";
import importExportImage from "./images/import_export.webp";
import importExportIcon from "./images/import_export_icon.webp";
import importExportPlusImage from "./images/import_export_plus_main.webp"
import importExportPlusIcon from "./images/import_export_plus_icon.webp"
import planUpdateImage from "./images/plan_update.webp";
import planUpdateIcon from "./images/plan_update_icon.webp";
import scriptPlannerImage from "./images/script_planner.webp";
import scriptPlannerIcon from "./images/script_planner_icon.webp";

export const applicationMapping = {
    "Addresses Apartments": {
        "description": "Add an Excel file to update address information and create addresses accordingly.",
        "displayName" : ["ADDRESSES", <br key="addresses_apartments_br"/>, "APARTMENTS"],
        "image": addressesAparmentsImage,
        "icon": addressesAparmentsIcon,
        "name": "Addresses Apartments",
        "orchestration": true,
        "url": "/addresses"
    },
    "Addresses Operations": {
        "description": "Create, modify or delete addresses and related aliases.",
        "displayName" : ["ADDRESSES", <br key="addresses_operations_br"/>, "OPERATIONS"],
        "image": addressesOperationsImage,
        "icon": addressesOperationsIcon,
        "name": "Addresses Operations",
        "orchestration": true,
        "url": "/addresses-operations"
    },
    "Apartments Usage Purpose": {
        "description": "Modify the usagePurpose parameters by introducing a file or a ExternalDoc IDId.",
        "displayName" : ["APARTMENTS", <br key="apartments_br"/>, "USAGE PURPOSE"],
        "image": aparmentsImage,
        "icon": aparmentsIcon,
        "name": "Apartments Usage Purpose",
        "orchestration": true,
        "url": "/apartments"
    },
    "BoQ Converter Tool": {
        "description": "Obtain a BoQ document by uploading the BoM file or the Setics ZIP file.",
        "displayName" : ["BoQ CONVERTER", <br key="boq_converter_br"/>, "TOOL"],
        "image": boqImage,
        "icon": boqIcon,
        "name": "BoQ Converter Tool",
        "orchestration": false,
        "url": "/boq-converter"
    },
    "Bundle Update": {
        "description": "Add a Plan ID file to check and correct the related info if necessary.",
        "displayName" : ["BUNDLE", <br key="bundle_update_br"/>, "UPDATE"],
        "image": bundleUpdateImage,
        "icon": bundleUpdateIcon,
        "name": "Bundle Update",
        "orchestration": true,
        "url": "/bundle-update"
    },
    "Circuit Creation": {
        "description": "Enter a file to create circuits, sections, logical and physical connections.",
        "displayName" : ["CIRCUIT", <br key="circuit_creation_br"/>, "CREATION"],
        "image": circuitCreationImage,
        "icon": circuitCreationIcon,
        "name": "Circuit Creation",
        "orchestration": true,
        "url": "/circuit-creation"
    },
    "Civil Works Update": {
        "description": "Enter an Excel file to create, modify or delete ducts.",
        "displayName" : ["CIVIL WORKS", <br key="civil_works_br"/>, "UPDATE"],
        "image": civilWorksImage,
        "icon": civilWorksIcon,
        "name": "Civil Works Update",
        "orchestration": true,
        "url": "/civil-works-update"
    },
    "Device Creation": {
        "description": "Create devices and update information related to Telecom Premises.",
        "displayName" : ["DEVICE", <br key="device_creation_br"/>, "CREATION"],
        "image": deviceCreationImage,
        "icon": deviceCreationIcon,
        "name": "Device Creation",
        "orchestration": true,
        "url": "/device-creation"
    },
    "Extra Values Update": {
        "description": "Create or update value-added information such as latitude or longitude.",
        "displayName" : ["EXTRA VALUES", <br key="extravalues_br"/>, "UPDATE"],
        "image": extravaluesImage,
        "icon": extravaluesIcon,
        "name": "Extra Values Update",
        "orchestration": true,
        "url": "/extravalues"
    },
    "Identifier Update": {
        "description": "Select an object type and add a file to perfom bulk identifier regularization.",
        "displayName" : ["IDENTIFIER", <br key="identifier_update_br"/>, "UPDATE"],
        "image": identifierUpdateImage,
        "icon": identifierUpdateIcon,
        "name": "Identifier Update",
        "orchestration": true,
        "url": "/identifier-update"
    },
    "Import Export": {
        "description": "Create a new whole Plan or just enter an existing Plan_ID to process and export data.",
        "displayName" : ["IMPORT", <br key="import_export_br"/>, "EXPORT"],
        "image": importExportImage,
        "icon": importExportIcon,
        "name": "Import Export",
        "orchestration": true,
        "url": "/import-export"
    },
    "Import Export +": {
        "description": "Add files or define a Plan/TelecomArea ID for new Import/Export functionalities.",
        "displayName" : ["IMPORT", <br key="import_export_plus_br"/>, "EXPORT +"],
        "image": importExportPlusImage,
        "icon": importExportPlusIcon,
        "name": "Import Export +",
        "orchestration": true,
        "url": "/import-export-plus"
    },
    "Plan Update": {
        "description": "Confirm a Plan ID to activate the system to run different utilities for the update.",
        "displayName" : ["PLAN", <br key="plan_update_br"/>, "UPDATE"],
        "image": planUpdateImage,
        "icon": planUpdateIcon,
        "name": "Plan Update",
        "orchestration": true,
        "url": "/plan-update"
    },
    "Script Planner": {
        "description": "Use this interface to run the available scripts and check recent activity for related requests.",
        "displayName" : ["SCRIPT", <br key="script_planner_br"/>, "PLANNER"],
        "image": scriptPlannerImage,
        "icon": scriptPlannerIcon,
        "name": "Script Planner",
        "orchestration": false,
        "url": "/script-planner"
    }
};
