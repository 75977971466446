import { Link, useNavigate } from "react-router-dom";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import "./Header.css";
import logo from "./images/logo.webp";

export default function Header({ hasAdmin, menu, setMenu }){
    const navigate = useNavigate()

    const handleLogoutClick = () => {
        localStorage.clear();
        window.location.replace(window.location.origin + "/login");
    }

    return (
        <div className="headerContainer">
            <Link className="noCursor" to="/"><img className="logoHeader" src={logo} alt="logo" /></Link>
            <div className="iconsHeaderContainer">
                <div className='iconHeaderContainer' onClick={handleLogoutClick}>
                    <LogoutOutlinedIcon className='iconLogoutHeader' />
                </div> 
                {hasAdmin && 
                    <div className='iconHeaderContainer' onClick={() => navigate("/admin/")}>
                        <SettingsOutlinedIcon className='iconHeader' />
                    </div>
                }
                <div className='iconHeaderContainer' onClick={() => navigate("/me")}>
                    <AccountCircleOutlinedIcon className='iconHeader'/>
                </div>
                <div className='iconHeaderContainer' onClick={() => setMenu(!menu)}>
                    <AppsOutlinedIcon className='iconHeader' />
                </div>                
            </div>
        </div>     
    );
}