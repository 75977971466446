import axios from "axios";

export async function deleteData({url, withAuthentication}){
    let config = {};
    if (withAuthentication === true){
        // Check if we have a token in localStorage to check
        const token = localStorage.getItem("cirotoolsToken");
        if (!token) {
            return [false, null];
        }
        config = {
            "headers": {
                "Authorization": "Bearer " + token
            }
        };
    }
    // Make delete request
    try{
        const resp = await axios.delete(url, config);
        return [true, resp]
    } catch (error) {
        return [false, error.response];
    }
    
}

export async function getData({url, params, withAuthentication, asBlob=false}){
    let config = {"params": params};
    if (withAuthentication === true){
        // Check if we have a token in localStorage to check
        const token = localStorage.getItem("cirotoolsToken");
        if (!token) {
            return [false, null];
        }
        config.headers = {
            "Authorization": "Bearer " + token
        };
    }
    if (asBlob) {
        config.responseType = "blob";
    }
    // Make get requests
    try{
        const resp = await axios.get(url, config);
        return [true, resp];
    } catch (error){
        return [false, error.response];
    }
}

export async function patchData({url, data, withAuthentication, customHeader=null}){
    let config = {"headers": {}};
    if (withAuthentication === true){
        // Check if we have a token in localStorage to check
        const token = localStorage.getItem("cirotoolsToken");
        if (!token) {
            return [false, null];
        }
        config["headers"] = Object.assign(config["headers"], {"Authorization": "Bearer " + token})
    }
    if (customHeader){
        config["headers"] = Object.assign(config["headers"], customHeader)
    }
    // Make patch request
    try{
        const resp = await axios.patch(url, data, config);
        return [true, resp];
    } catch (error) {
        return [false, error.response];
    }
}

export async function postData({url, data, withAuthentication, asBlob=false, customHeader=null}){
    let config = {"headers": {}};
    if (withAuthentication === true){
        // Check if we have a token in localStorage to check
        const token = localStorage.getItem("cirotoolsToken");
        if (!token) {
            return [false, null];
        }
        config["headers"] = Object.assign(config["headers"], {"Authorization": "Bearer " + token})
    }
    if (customHeader){
        config["headers"] = Object.assign(config["headers"], customHeader)
    }

    if (asBlob) {
        config.responseType = "blob";
    }
    // Make post request
    try{
        const resp = await axios.post(url, data, config);
        return [true, resp];
    } catch (error) {
        return [false, error.response];
    }
}

export async function putData({url, data, withAuthentication}){
    let config = {};
    if (withAuthentication === true){
        // Check if we have a token in localStorage to check
        const token = localStorage.getItem("cirotoolsToken");
        if (!token) {
            return [false, null];
        }
        config = {
            "headers": {
                "Authorization": "Bearer " + token
            }
        };
    }
    // Make put request
    try{
        const resp = await axios.put(url, data, config);
        return [true, resp];
    } catch (error) {
        return [false, error.response];
    }
}