import { motion } from "framer-motion";
import "./LoadingPage.css";
import animationBox from '../../Core/Animations';

export default function LoadingPage() {
    return (
        <motion.div 
            className='spinnerContainer'
            initial="fadeOut"
            animate="fadeIn"
            exit="fadeOut"
            transition={{ duration: 0.5 }}
            variants={animationBox}
        />
    );
}
