import "./SmallKPI.css";

function getContainerClass(color) {
    let cssClass = "smallKPIContainer";
    if (color === "blue") {
        cssClass += " smallKPIBlueContainer";
    } else if (color === "yellow") {
        cssClass += " smallKPIYellowContainer";
    } else if (color === "green") {
        cssClass += " smallKPIGreenContainer";
    } else if (color === "red") {
        cssClass += " smallKPIRedContainer";
    }
    return cssClass;
}

export default function SmallKPI({ color, kpi }) {

    const containerClass = getContainerClass(color);

    return (
        <div className={containerClass}>
            <span className="smallKPI">{kpi}</span>
        </div>
    );
}