import animationBox from "../../Core/Animations";
import activityLogo from "./images/activity_icon.webp";
import { motion } from "framer-motion";
import useMediaQuery from '@mui/material/useMediaQuery';
import "./Menu.css";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Link } from "react-router-dom";
import { applicationMapping } from "../../Core/ApplicationsMapping";
import { hasOrchestration } from "../../Core/Utils";
import SmallKPI from "./Subcomponents/SmallKPI/SmallKPI";
import { useEffect, useState } from "react";
import Backend from "../../Services/Backend";

function formatKPI(kpi) {
    if (kpi >= 100) {
        return "+99";
    } else if (kpi === 0) {
        return "-";
    } else {
        return String(kpi);
    }
}

export default function Menu({ apps, menu, setMenu }) {
    const duration = 0.35;
    const isMobile = useMediaQuery("(max-width: 700px), (orientation:portrait)");
    const paintOrchestration = hasOrchestration(apps);
    const [queued, setQueued] = useState("-");
    const [running, setRunning] = useState("-");
    const [success, setSuccess] = useState("-");
    const [failed, setFailed] = useState("-");

    useEffect(() => {
        (async () => {
            const [valid, resp] = await Backend.me.getOrchestration();
            if (valid) {
                setQueued(formatKPI(resp.data.queued));
                setRunning(formatKPI(resp.data.running));
                setSuccess(formatKPI(resp.data.success));
                setFailed(formatKPI(resp.data.failed));
            }
        })();
    }, []);

    const getAppItem = (app) => {
        const iconSrc = applicationMapping[app.name].icon
        const name = applicationMapping[app.name].name;
        const url = applicationMapping[app.name].url;

        return (
            <ListItem key={name} disablePadding secondaryAction={<Link to={url} onClick={() => setMenu(false)}><img src={iconSrc} width="24px" height="24px" alt={name}/></Link>}>
                <ListItemButton component={Link} to={url} onClick={() => setMenu(false)}>
                    <ListItemText primary={name} className="textMenu" disableTypography/>
                </ListItemButton>
            </ListItem>
        );
    }

    const getAppsItems = (apps) => {
        return (
            <List>
                {apps.map(app => getAppItem(app))}
            </List>
        );
    }

    return (
        <>
            <motion.div 
                className={menu? 'shadowMenuContainer' : 'shadowMenuContainer shadowMenuHide'}
                variants={animationBox}
                initial="fadeOut"
                animate={menu ? "fadeInPartial" : "fadeOut"}
                transition={{ duration: duration }}
                onClick={() => setMenu(!menu)}
            />
            <motion.div 
                className="menuContainer"
                variants={animationBox}
                initial="collapse"
                animate={menu ? (isMobile ? {width: "100%"} : {width: "20%"}) : "collapse"}
                transition={{ duration: duration }}
            >
                <motion.div 
                    className="orchestrationMenuContainer"
                    variants={animationBox}
                    initial="fadeOut"
                    animate={menu ? "fadeIn" : "fadeOut"}
                    transition={{delay: duration, duration: duration}}
                    style={!menu && {display: "none"}}
                >
                    {paintOrchestration && (
                        <ListItemButton className="orchestrationButtonContainer" component={Link} to="/activity" onClick={() => setMenu(false)}>
                            <div className="orchestrationTitleContainer">
                                <span className="orchestrationTitle">My Activity</span>
                            </div>
                            <div className="orchestrationDataContainer">
                                <div className="orchestrationCardsContainer">
                                    <div className="orchestrationCardContainer">
                                        <SmallKPI color="blue" kpi={queued}/>
                                    </div>
                                    <div className="orchestrationCardContainer">
                                        <SmallKPI color="yellow" kpi={running}/>
                                    </div>
                                    <div className="orchestrationCardContainer">
                                        <SmallKPI color="green" kpi={success}/>
                                    </div>
                                    <div className="orchestrationCardContainer">
                                        <SmallKPI color="red" kpi={failed}/>
                                    </div>
                                </div>
                                <div className="orchestrationLogoContainer"><img className="orchestrationLogo" src={activityLogo} width="32px" height="32px" alt="activity"/></div>
                            </div>  
                        </ListItemButton>                      
                    )}
                </motion.div>
                <motion.div 
                    className="listMenuContainer"
                    variants={animationBox}
                    initial="fadeOut"
                    animate={menu ? "fadeIn" : "fadeOut"}
                    transition={{delay: duration, duration: duration}}
                    style={!menu && {display: "none"}}
                >
                   {getAppsItems(apps)}
                </motion.div>
            </motion.div>
        </>

    );
}