import { applicationMapping } from "./ApplicationsMapping";

export const checkPermissions = (isAdmin, url, apps) => {
   if (isAdmin) {
      return true;
   } else if (url.includes("admin")) {
      return false;
   } else if (url === "/" || url === "/activity" || url === "/me") {
      return true;
   } else {
      for (let app of apps){
         if (applicationMapping[app.name].url === url) {
            return true;
         }
      }
      return false;
   }
};

export const getWidthColums = (columns) => {
   let newColumns = [];
   for (const column of columns){
       if (window.innerWidth > 900){
           column.flex = column.width
       }
       newColumns.push(column);
   }
   return newColumns;
}

export const hasOrchestration = (apps) => {
   let paint = false;
   for (let app of apps) {
       if (applicationMapping[app.name]["orchestration"] === true){
           paint = true;
           break;
       }
   }
   return paint;
}

export const hexToRGB = hex => {
   let alpha = false,
     h = hex.slice(hex.startsWith('#') ? 1 : 0);
   if (h.length === 3) h = [...h].map(x => x + x).join('');
   else if (h.length === 8) alpha = true;
   h = parseInt(h, 16);
   return (
     'rgb' +
     (alpha ? 'a' : '') +
     '(' +
     (h >>> (alpha ? 24 : 16)) +
     ', ' +
     ((h & (alpha ? 0x00ff0000 : 0x00ff00)) >>> (alpha ? 16 : 8)) +
     ', ' +
     ((h & (alpha ? 0x0000ff00 : 0x0000ff)) >>> (alpha ? 8 : 0)) +
     (alpha ? `, ${(h & 0x000000ff) / 255}` : '') +
     ')'
   );
 };

 const RADIAN = Math.PI / 180;
 export const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
   const radius = innerRadius + (outerRadius - innerRadius) * 0.1;
   const x = cx + radius * Math.cos(-midAngle * RADIAN);
   const y = cy + radius * Math.sin(-midAngle * RADIAN);
   return (
     <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
       { percent > 0 && `${(percent * 100).toFixed(0)}%`}
     </text>
   );
 };
 
 export const renderDatetime = (params) => {
   const date = new Date(params.value);
   const dateFormat = date.getFullYear() + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + ("0" + date.getDate()).slice(-2) + " " + ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2) + " UTC";
   return dateFormat
}

export const validateEmail = (mail) => {
    const regex = /([A-Za-z0-9]+[._-])*[A-Za-z0-9]+@[A-Za-z0-9-]+(\.[A-Z|a-z]{2,})+/;
    return regex.test(mail);
}

export const validatePassword = (password) => {
    const regex = /^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d)(?=.*[-_!#$%&? "]).*$/;
    return regex.test(password)
}
