import {deleteData, getData, patchData, postData, putData} from "../Core/Requests";


const Backend = {
    "admin": {
        async createCompany(companyName, companyDescription, color, logo, cirotools, ciroapps){
            const url = process.env.REACT_APP_API_URL + "/administration/company/";
            const apps = cirotools.concat(ciroapps);
            const form = new FormData();
            form.append("name", companyName);
            form.append("description", companyDescription);
            form.append("logo", logo);
            form.append("color", color);
            for (let app of apps){
                form.append("apps", app);
            }
            const [valid, resp] = await postData({url: url, data: form, withAuthentication: true});
            return [valid, resp];
        },
        async createUser(userUsername, userCompanyId, userName, userLastName, userEmail, userPassword, userCirotools, userCiroapps){
            const url = process.env.REACT_APP_API_URL + "/administration/user/";
            const data = {
                "username": userUsername,
                "name": userName,
                "lastname": userLastName,
                "email": userEmail,
                "cirotools_active": userCirotools,
                "ciroapps_active": userCiroapps,
                "company_id": userCompanyId,
                "password": userPassword
            }
            const [valid, resp] = await postData({url: url, data: data, withAuthentication: true});
            return [valid, resp];
        },
        async deleteCompany(companyId) {
            const url = process.env.REACT_APP_API_URL + "/administration/company/" + companyId;
            const [valid, resp] = await deleteData({url: url, withAuthentication: true});
            return [valid, resp];
        },
        async deleteUser(userId) {
            const url = process.env.REACT_APP_API_URL + "/administration/user/" + userId;
            const [valid, resp] = await deleteData({url: url, withAuthentication: true});
            return [valid, resp];
        },        
        async getApps() {
            const url = process.env.REACT_APP_API_URL + "/administration/app/";
            const [valid, resp] = await getData({url: url, params: {}, withAuthentication: true});
            return [valid, resp];
        },
        async getCompanies() {
            const url = process.env.REACT_APP_API_URL + "/administration/company/";
            const [valid, resp] = await getData({url: url, params: {}, withAuthentication: true});
            return [valid, resp];
        },
        async getCompany(companyId) {
            const url = process.env.REACT_APP_API_URL + "/administration/company/" + companyId;
            const [valid, resp] = await getData({url: url, params: {}, withAuthentication: true});
            return [valid, resp];
        },
        async getLogo(company_id) {
            const url = process.env.REACT_APP_API_URL + "/administration/company/" + company_id + "/logo";
            const [valid, resp] = await getData({url: url, params: {}, withAuthentication: true, asBlob: true});
            return [valid, resp];
        },
        async getUser(userId){
            const url = process.env.REACT_APP_API_URL + "/administration/user/" + userId;
            const [valid, resp] = await getData({url: url, params: {}, withAuthentication: true});
            return [valid, resp];
        },
        async getUsers(){
            const url = process.env.REACT_APP_API_URL + "/administration/user/";
            const [valid, resp] = await getData({url: url, params: {}, withAuthentication: true});
            return [valid, resp];            
        },
        async modifyCompany(companyId, companyName, companyDescription, color, logo, cirotools, ciroapps){
            const url = process.env.REACT_APP_API_URL + "/administration/company/" + companyId;
            const apps = cirotools.concat(ciroapps);
            const form = new FormData();
            form.append("name", companyName);
            form.append("description", companyDescription);
            form.append("logo", logo);
            form.append("color", color);
            for (let app of apps){
                form.append("apps", app);
            }
            const [valid, resp] = await putData({url: url, data: form, withAuthentication: true});
            return [valid, resp];
        },
        async modifyUser(userId, userName, userLastName, userEmail, userPassword, userCirotools, userCiroapps){
            const url = process.env.REACT_APP_API_URL + "/administration/user/" + userId;
            const data = {
                "name": userName,
                "lastname": userLastName,
                "email": userEmail,
                "cirotools_active": userCirotools,
                "ciroapps_active": userCiroapps,
            }
            if (userPassword !== ""){
                data["password"] =  userPassword;
            }   
            const [valid, resp] = await patchData({url: url, data: data, withAuthentication: true});
            return [valid, resp];
        },
    },
    "auth": {
        async checkToken() {
            const url = process.env.REACT_APP_API_URL + "/authentication/permissions";
            const [valid, resp] = await getData({url: url, params: {}, withAuthentication: true});
            return [valid, resp];
        },
        async login(username, password) {
            const url = process.env.REACT_APP_API_URL + "/authentication/login";
            const form = new FormData();
            form.append("username", username);
            form.append("password", password);
            form.append("client_id", "cirotools");
            const [valid, resp] = await postData({url: url, data: form, withAuthentication: false});
            const access_token = valid ? resp.data.access_token : null;
            return [valid, access_token];
        },
        async requestPassword(email) {          
            const url = process.env.REACT_APP_API_URL + "/authentication/password/request";
            const data = {"email": email };
            const header = {"client-id": "cirotools"}
            const [valid, ] = await postData({url: url, data: data, customHeader: header, withAuthentication: false});
            return valid;
        }
    },
    "cirotools":{
        "addresses": {
            async launch(file) {
                const url = process.env.REACT_APP_API_URL + "/addresses_apartments/launch/";
                const form = new FormData();
                form.append("file", file);
                const [valid, resp] = await postData({url: url, data: form, withAuthentication: true})
                return [valid, resp];
            }
        },
        "addressesOperations": {
            async launch(file) {
                const url = process.env.REACT_APP_API_URL + "/addresses_operations/launch/";
                const form = new FormData();
                form.append("file", file);
                const [valid, resp] = await postData({url: url, data: form, withAuthentication: true})
                return [valid, resp];
            }
        },
        "apartments": {
            async launch(file, extDocId) {
                const url = process.env.REACT_APP_API_URL + "/apartments/launch/";
                const form = new FormData();
                if (file) {
                    form.append("file", file);
                } else {
                    form.append("externaldoc_id", extDocId);
                }
                const [valid, resp] = await postData({url: url, data: form, withAuthentication: true})
                return [valid, resp];
            }
        },
        "boqConverter": {
            async convert(file) {
                const url = process.env.REACT_APP_API_URL + "/converter/convert/";
                const form = new FormData();
                form.append("file", file);
                const [valid, resp] = await postData({url: url, data: form, withAuthentication: true, asBlob: true})
                return [valid, resp];
            }
        },
        "bundleUpdate" : {
            async launch(file) {
                const url = process.env.REACT_APP_API_URL + "/bundle_update/launch/";
                const form = new FormData();
                form.append("file", file);
                const [valid, resp] = await postData({url: url, data: form, withAuthentication: true})
                return [valid, resp];
            }  
        },
        "circuitCreation" : {
            async launch(file) {
                const url = process.env.REACT_APP_API_URL + "/circuit_creation/launch/";
                const form = new FormData();
                form.append("file", file);
                const [valid, resp] = await postData({url: url, data: form, withAuthentication: true})
                return [valid, resp];
            }
        },
        "civilWorks" : {
            async launch(file) {
                const url = process.env.REACT_APP_API_URL + "/civil_works_update/launch/";
                const form = new FormData();
                form.append("file", file);
                const [valid, resp] = await postData({url: url, data: form, withAuthentication: true})
                return [valid, resp];
            }
        },
        "deviceCreation" : {
            async launch(file) {
                const url = process.env.REACT_APP_API_URL + "/device_creation/launch/";
                const form = new FormData();
                form.append("file", file);
                const [valid, resp] = await postData({url: url, data: form, withAuthentication: true})
                return [valid, resp];
            }
        },
        "extravalues" : {
            async launch(optionSelected, file, extDocId) {
                const url = process.env.REACT_APP_API_URL + "/extravalues/launch/";
                const form = new FormData();
                form.append("collection", optionSelected);
                if (file) {
                    form.append("file", file);
                } else {
                    form.append("externaldoc_id", extDocId);
                }
                const [valid, resp] = await postData({url: url, data: form, withAuthentication: true})
                return [valid, resp];
            }
        },
        "identifierUpdate": {
            async launch(collection, file, extDocId) {
                const url = process.env.REACT_APP_API_URL + "/identifier_update/launch/";
                const form = new FormData();
                form.append("collection", collection);
                if (file) {
                    form.append("file", file);
                } else {
                    form.append("externaldoc_id", extDocId);
                }
                const [valid, resp] = await postData({url: url, data: form, withAuthentication: true})
                return [valid, resp];                
            }
        },
        "importExport": {
            async launchExport(planId){
                const url = process.env.REACT_APP_API_URL + "/import_export/launch/export";
                const data = {"plan_id": planId};
                const [valid, resp] = await postData({url: url, data: data, withAuthentication: true})
                return [valid, resp];
            },
            async launchImport(planName, file) {
                const url = process.env.REACT_APP_API_URL + "/import_export/launch/import";
                const form = new FormData();
                form.append("plan_name", planName);
                form.append("file", file);
                const [valid, resp] = await postData({url: url, data: form, withAuthentication: true})
                return [valid, resp];
            }
        },
        "importExportPlus": {
            async launchExport(mode, idx){
                const url = process.env.REACT_APP_API_URL + "/import_export_plus/launch/export";
                let data = {}
                if (mode === "plan_id"){
                    data = {"plan_id": idx};
                } else {
                    data = {"telecom_area_id": idx}
                }
                const [valid, resp] = await postData({url: url, data: data, withAuthentication: true})
                return [valid, resp];
            },
            async launchImport(file) {
                const url = process.env.REACT_APP_API_URL + "/import_export_plus/launch/import";
                const form = new FormData();
                form.append("file", file);
                const [valid, resp] = await postData({url: url, data: form, withAuthentication: true})
                return [valid, resp];
            }
        },        
        "planUpdate": {
            async launch(planId) {
                const url = process.env.REACT_APP_API_URL + "/plan_update/launch/";
                const data = {"plan_id": planId};
                const [valid, resp] = await postData({url: url, data: data, withAuthentication: true});
                return [valid, resp];
            }
        },
        "scriptPlanner": {
            async getExecutions() {
                const url = process.env.REACT_APP_API_URL + "/script_planner/execution/";
                const [valid, resp] = await getData({url: url, params: {}, withAuthentication: true});
                return [valid, resp];
            },
            async getOptions() {
                const url = process.env.REACT_APP_API_URL + "/script_planner/script/";
                const [valid, resp] = await getData({url: url, params: {}, withAuthentication: true});
                return [valid, resp];
            },
            async launch(repository, name) {
                const url = process.env.REACT_APP_API_URL + "/script_planner/execution/";
                const data = {"repository": repository, "script": name};
                const [valid, resp] = await postData({url: url, data: data, withAuthentication: true});
                return [valid, resp];
            }
        }
    },
    "me": {
        async getLogo(company) {
            const url = process.env.REACT_APP_API_URL + "/me/company/logo?company=" + company;
            const [valid, resp] = await getData({url: url, params: {}, withAuthentication: true, asBlob: true});
            return [valid, resp];
        },
        async getOrchestration(){
            const url = process.env.REACT_APP_API_URL + "/me/orchestration/";
            const [valid, resp] = await getData({url: url, params: {}, withAuthentication: true});
            return [valid, resp];            
        },
        async getUserInfo() {
            const url = process.env.REACT_APP_API_URL + "/me/user/";
            const [valid, resp] = await getData({url: url, params: {}, withAuthentication: true});
            return [valid, resp];
        },
        async modifyPassword(oldPassword, newPassword){
            const url = process.env.REACT_APP_API_URL + "/me/password/modify";
            const data = {"password_old": oldPassword, "password": newPassword};
            const [valid, resp] = await patchData({url: url, data: data, withAuthentication: true});
            return [valid, resp];
        },
        async resetPassword(password, forgotMode){
            let url = process.env.REACT_APP_API_URL;
            let header = {};
            if (forgotMode) {
                url += "/me/password/forgot";
                header["client-id"] = "cirotools";
            } else {
                url += "/me/password/reset";
            }
            const data = {"password": password};
            const [valid, resp] = await patchData({url: url, data: data, customHeader: header, withAuthentication: true});
            const access_token = valid && forgotMode? resp.data.access_token : null;
            return [valid, resp, access_token];
        }
    },
    "orchestration": {
        async getOrchestration(){
            const url = process.env.REACT_APP_API_URL + "/orchestration/orchestration/";
            const [valid, resp] = await getData({url: url, params: {}, withAuthentication: true});
            return [valid, resp];
        }
    }
}

export default Backend;
