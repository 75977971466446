const animationBox = {
    fadeIn: {
        opacity: 1,
    },
    fadeInPartial: {
        opacity: 0.5,
    },
    fadeOut: {
        opacity: 0,
    },
    slideRight: {
        x: "100vw", 
        opacity: 0,
    },
    slideLeft: {
        x: "-100vw",
        opacity: 0,
    },
    slideUp: {
        y: "-20vh",
        opacity: 0
    },
    enter: { 
        x: "0vw",
        y: "0vh",
        opacity: 1,
        visibility: "visible"  
    },
    collapse: {
        width: 0
    }
}

export default animationBox;
