import { Route, Routes, useLocation } from "react-router";
import { lazy, Suspense } from 'react';
import { AnimatePresence } from "framer-motion";
import LayoutPage from "../LayoutPage/LayoutPage";
import LoadingPage from "../LoadingPage/LoadingPage";

// Lazy loading components
const ActivityPage = lazy(() => import("../ActivityPage/ActivityPage"));
const AddressesOperationsPage = lazy(() => import("../Cirotools/AddressesOperationsPage/AddressesOperationsPage"));
const AddressesPage = lazy(() => import("../Cirotools/AddressesPage/AddressesPage"));
const AdminPage = lazy(() => import("../Admin/AdminPage/AdminPage"));
const ApartmentsPage = lazy(() => import("../Cirotools/ApartmentsPage/ApartmentsPage"));
const BoqConverterPage = lazy(() => import("../Cirotools/BoqConverterPage/BoqConverterPage"));
const BundleUpdatePage = lazy(() => import("../Cirotools/BundleUpdatePage/BundleUpdatePage"));
const CircuitCreationPage = lazy(() => import("../Cirotools/CircuitCreationPage/CircuitCreationPage"));
const CivilWorksUpdatePage = lazy(() => import("../Cirotools/CivilWorksUpdatePage/CivilWorksUpdatePage"));
const CompaniesFormPage = lazy(() => import("../Admin/CompaniesFormPage/CompaniesFormPage"));
const CompaniesPage = lazy(() => import("../Admin/CompaniesPage/CompaniesPage"));
const DeviceCreationPage = lazy(() => import("../Cirotools/DeviceCreationPage/DeviceCreationPage"));
const ExtravaluesPage = lazy(() => import("../Cirotools/ExtravaluesPage/ExtravaluesPage"));
const HomePage = lazy(() => import("../HomePage/HomePage"));
const IdentifierUpdatePage = lazy(() => import("../Cirotools/IdentifierUpdatePage/IdentifierUpdatePage"))
const ImportExportPage = lazy(() => import("../Cirotools/ImportExportPage/ImportExportPage"));
const ImportExportPlusPage = lazy(() => import("../Cirotools/ImportExportPlusPage/ImportExportPlusPage"));
const LoginPage = lazy(() => import("../LoginPage/LoginPage"));
const PlanUpdatePage = lazy(() => import("../Cirotools/PlanUpdatePage/PlanUpdatePage"));
const ResetPasswordPage = lazy(() => import("../ResetPasswordPage/ResetPasswordPage"));
const ScriptPlannerPage = lazy(() => import("../Cirotools/ScriptPlannerPage/ScriptPlannerPage"));
const UsersPage = lazy(() => import("../Admin/UsersPage/UsersPage"));
const UsersFormPage = lazy(() => import("../Admin/UsersFormPage/UsersFormPage"));
const UserInfoPage = lazy(() => import("../UserInfoPage/UserInfoPage"));

export default function Router() {
    const location = useLocation();
    return (
        <AnimatePresence>
            <Routes key={location.pathname} location={location}>
                <Route path="*" element={
                    <Suspense fallback={<LoadingPage />}>
                        <p>Usted se ha perdido</p>
                    </Suspense>                    
                } />                
                <Route path="/login" element={
                    <Suspense fallback={<LoadingPage />}>
                        <LoginPage />
                    </Suspense>                    
                } />
                <Route path="/reset-password/:jwt" element={
                    <Suspense fallback={<LoadingPage />}>
                        <ResetPasswordPage />
                    </Suspense>                    
                } />
                <Route path="/" element={<LayoutPage />}>
                    <Route index element={
                        <Suspense fallback={<LoadingPage />}>
                            <HomePage />
                        </Suspense>
                    } />
                    <Route path="/activity" element={
                        <Suspense fallback={<LoadingPage />}>
                            <ActivityPage />
                        </Suspense>
                    } />
                    <Route path="/admin/" element={
                        <Suspense fallback={<LoadingPage />}>
                            <AdminPage />
                        </Suspense>
                    } />
                    <Route path="/admin/companies/" element={
                        <Suspense fallback={<LoadingPage />}>
                            <CompaniesPage />
                        </Suspense>
                    } />
                    <Route path="/admin/companies/create" element={
                        <Suspense fallback={<LoadingPage />}>
                            <CompaniesFormPage />
                        </Suspense>
                    } />
                    <Route path="/admin/companies/:companyId" element={
                        <Suspense fallback={<LoadingPage />}>
                            <CompaniesFormPage />
                        </Suspense>
                    } />
                    <Route path="/admin/users/" element={
                        <Suspense fallback={<LoadingPage />}>
                            <UsersPage />
                        </Suspense>
                    } />
                    <Route path="/admin/users/create" element={
                        <Suspense fallback={<LoadingPage />}>
                            <UsersFormPage />
                        </Suspense>
                    } />
                    <Route path="/admin/users/:userId" element={
                        <Suspense fallback={<LoadingPage />}>
                            <UsersFormPage />
                        </Suspense>
                    } />
                    <Route path="/me" element={
                        <Suspense fallback={<LoadingPage />}>
                            <UserInfoPage />
                        </Suspense>
                    } />
                    <Route path="/addresses" element={
                        <Suspense fallback={<LoadingPage />}>
                            <AddressesPage />
                        </Suspense>
                    } />
                    <Route path="/addresses-operations" element={
                        <Suspense fallback={<LoadingPage />}>
                            <AddressesOperationsPage />
                        </Suspense>
                    } />
                    <Route path="/apartments" element={
                        <Suspense fallback={<LoadingPage />}>
                            <ApartmentsPage />
                        </Suspense>
                    } />
                    <Route path="/boq-converter" element={
                        <Suspense fallback={<LoadingPage />}>
                            <BoqConverterPage />
                        </Suspense>
                    } />
                    <Route path="/bundle-update" element={
                        <Suspense fallback={<LoadingPage />}>
                            <BundleUpdatePage />
                        </Suspense>
                    } />
                    <Route path="/circuit-creation" element={
                        <Suspense fallback={<LoadingPage />}>
                            <CircuitCreationPage />
                        </Suspense>
                    } />
                    <Route path="/civil-works-update" element={
                        <Suspense fallback={<LoadingPage />}>
                            <CivilWorksUpdatePage />
                        </Suspense>
                    } />
                    <Route path="/device-creation" element={
                        <Suspense fallback={<LoadingPage />}>
                            <DeviceCreationPage />
                        </Suspense>
                    } />
                    <Route path="/extravalues" element={
                        <Suspense fallback={<LoadingPage />}>
                            <ExtravaluesPage />
                        </Suspense>
                    } />
                    <Route path="/identifier-update" element={
                        <Suspense fallback={<LoadingPage />}>
                            <IdentifierUpdatePage />
                        </Suspense>
                    } />
                    <Route path="/import-export" element={
                        <Suspense fallback={<LoadingPage />}>
                            <ImportExportPage />
                        </Suspense>
                    } />
                    <Route path="/import-export-plus" element={
                        <Suspense fallback={<LoadingPage />}>
                            <ImportExportPlusPage />
                        </Suspense>
                    } />
                    <Route path="/plan-update" element={
                        <Suspense fallback={<LoadingPage />}>
                            <PlanUpdatePage />
                        </Suspense>
                    } />
                    <Route path="/script-planner" element={
                        <Suspense fallback={<LoadingPage />}>
                            <ScriptPlannerPage />
                        </Suspense>
                    } />                                       
                </Route>
            </Routes>
        </AnimatePresence>
    );
}
