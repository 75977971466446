import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "./LayoutPage.css";
import Backend from "../../Services/Backend";
import Header from "../Header/Header";
import { checkPermissions } from "../../Core/Utils";
import Menu from "../Menu/Menu";

const getGreating = (name) => {
    if (name === ""){
        return "";
    }
    const greatings = [
        "How is it going " + name + "?",
        "Nice to see you again " + name + "!",
        "Welcome back " + name + "!",
        "Hello again " + name + "! What's new?",
        "Hey! what's up " + name + "?"
    ];
    return greatings[greatings.length * Math.random() | 0];
}

export default function Layout() {
    const navigate = useNavigate();
    const location = useLocation();
    const [isAdmin, setIsAdmin] = useState(false);
    const [apps, setApps] = useState([]);
    const [color, setColor] = useState("#000000");
    const [companyName, setCompanyName] = useState("");
    const [greating, setGreating] = useState("");
    const [menu, setMenu] = useState(false);

    useEffect(() => {
        (async function(){
            const [valid, resp] = await Backend.auth.checkToken();
            if (!valid) {
                if (resp) {
                    if (resp.status === 403){
                        navigate("/reset-password/renew");
                    } else {
                        localStorage.clear();
                        window.location.replace(window.location.origin + "/login");
                    }
                } else {
                    localStorage.clear();
                    window.location.replace(window.location.origin + "/login");
                }
            } else {
                const isAdmin = resp.data.company.is_admin;
                const apps = resp.data.company.modules;
                const hasPermissions = checkPermissions(isAdmin, location.pathname, apps);
                if (hasPermissions){
                    setIsAdmin(isAdmin);
                    setApps(apps);
                    setColor(resp.data.company.color);
                    if (companyName === "") {
                        setCompanyName(resp.data.company.name);
                    }
                    if (greating === "") {
                        setGreating(getGreating(resp.data.name));
                    }
                } else {
                    navigate("/");
                }
            }
        })();
    }, [navigate, location, greating, companyName]);

    return (
        <div>
            <Header key="Header" hasAdmin={ isAdmin } menu={ menu } setMenu={ setMenu }/>
            <Menu key="Menu" apps={ apps } menu={ menu } setMenu={ setMenu }/>
            <div className="outletContainer">
                <Outlet key="Outlet" context={[apps, color, companyName, greating]}/>
            </div>
        </div>
    );
}